import { Checkbox, IconButton, InputBase, TextareaAutosize } from "@material-ui/core";
import { Subtract } from "@styled-icons/remix-line/Subtract";
import { FieldArray } from "formik";
import React from "react";
import { CandidateExperienceExpertiseType } from "types/OnboardCandidate";
import { DefaultExperience } from "../OnboardCandidateContants";
import Projects from "./Projects";

type IProps = {
    values: CandidateExperienceExpertiseType,
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
    formError: any;
    submitCount: number;
    addProjectBtnRef: React.MutableRefObject<HTMLInputElement | null>;

}

const Experience = ({
    values,
    setFieldValue,
    formError,
    submitCount,
    addProjectBtnRef
}: IProps) => {

    const addExperience = (push: (obj: any) => void) => {
        push({ ...DefaultExperience });
    }

    return (
        <FieldArray name="experiences" >
            {({ push: pushExp, remove: removeExp, name }) => (
                <>
                    {values.experiences.map((exp, expIdx) => (
                        <React.Fragment key={'experiences_' + expIdx}>
                            <span className="mb-2 d-block">Experience {expIdx + 1}</span>
                            <div className="row mb-4">
                                <div className="col-3">
                                    <span className="text-muted small">Company</span>
                                    <InputBase
                                        placeholder="Company Name"
                                        name="companyName"
                                        className="input-element mt-2"
                                        value={exp.companyName}
                                        onChange={(e) => {
                                            setFieldValue(`${name}.${expIdx}.companyName`, e.target.value);
                                        }}
                                    />
                                    {typeof formError.experiences?.[expIdx] === 'object' && formError.experiences?.[expIdx]?.companyName && submitCount > 0 &&
                                        <span className="text-danger error-font">{formError.experiences?.[expIdx]?.companyName}</span>}
                                </div>
                                <div className="col-3">
                                    <span className="text-muted small">Job Title</span>
                                    <InputBase
                                        placeholder="Job Title"
                                        className="input-element mt-2"
                                        name='profile'
                                        value={exp.profile}
                                        onChange={(e) => setFieldValue(`${name}.${expIdx}.profile`, e.target.value)}
                                    />
                                    {typeof formError.experiences?.[expIdx] === 'object' && formError.experiences?.[expIdx]?.profile && submitCount > 0 &&
                                        <span className="text-danger error-font">{formError.experiences?.[expIdx]?.profile}</span>}
                                </div>
                                <div className="col-2">
                                    <span className="text-muted small">Start Date</span>
                                    <InputBase
                                        className="input-element mt-2"
                                        type="date"
                                        value={exp.startDate}
                                        name="startDate"
                                        onChange={(e) => setFieldValue(`${name}.${expIdx}.startDate`, e.target.value)}
                                    />
                                    {typeof formError.experiences?.[expIdx] === 'object' && formError.experiences?.[expIdx]?.startDate && submitCount > 0 &&
                                        <span className="text-danger error-font">{formError.experiences?.[expIdx]?.startDate}</span>}
                                </div>
                                <div className="col-2">
                                    <span className="text-muted small">End Date</span>
                                    <InputBase
                                        className="input-element mt-2"
                                        type="date"
                                        value={exp.endDate}
                                        name="endDate"
                                        onChange={(e) => setFieldValue(`${name}.${expIdx}.endDate`, e.target.value)}
                                        disabled={exp.currentJob}
                                    />
                                    {typeof formError.experiences?.[expIdx] === 'object' && formError.experiences?.[expIdx]?.endDate && submitCount > 0 &&
                                        <span className="text-danger error-font">{formError.experiences?.[expIdx]?.endDate}</span>}
                                </div>
                                <div className="col-1">
                                    <span className="text-muted small">Current Job?</span>
                                    <div className="mt-2">
                                        <Checkbox
                                            className="p-0"
                                            checked={exp.currentJob}
                                            name="currentJob"
                                            onChange={(e) => setFieldValue(`${name}.${expIdx}.currentJob`, e.target.checked)}
                                        />
                                    </div>
                                </div>
                                <div className="col-1">
                                    <div className="d-flex align-items-end w-100 h-100">
                                        <IconButton style={{
                                            background: 'tomato'
                                        }}
                                            onClick={() => removeExp(expIdx)}
                                        >
                                            <Subtract width={'15px'} color="#ffffff" />
                                        </IconButton>
                                    </div>
                                </div>
                            </div>
                            <div className="row mb-4">
                                <div className="col-md-8">
                                    <span className="text-muted small">Experience Summary</span>
                                    <TextareaAutosize
                                        minRows={2}
                                        className="input-element mt-2"
                                        name='experienceSummary'
                                        value={exp.experienceSummary}
                                        onChange={(e) => setFieldValue(`${name}.${expIdx}.experienceSummary`, e.target.value)}
                                        placeholder="Experience Summary"
                                    />
                                </div>
                            </div>
                            <Projects
                                exp={exp}
                                formError={formError}
                                parentIdx={expIdx}
                                parentName={name}
                                setFieldValue={setFieldValue}
                                submitCount={submitCount}
                            />
                        </React.Fragment>
                    ))}
                    <input hidden type="button" ref={addProjectBtnRef} onClick={() => addExperience(pushExp)} />
                </>
            )}
        </FieldArray>
    )
}

export default Experience;